import { loadable } from 'nordic/lazy';

/* istanbul ignore next */
const SeoTopKeywordsIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-seo-top-key-words-intervention" */
    '../../../seo-top-keywords-intervention/seo-top-keywords-intervention.mobile'
  ),
);

/* istanbul ignore next */
const SeoKeywordSummaryIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-seo-keyword-summary-intervention" */
    '../../../seo-keyword-summary-intervention/seo-keyword-summary-intervention.mobile'
  ),
);

/* istanbul ignore next */
const FilterIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-filter" */
    '../../../filter-intervention/mobile'
  ),
);

/* istanbul ignore next */
const ContentIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-content" */
    '../../../content/content-intervention.mobile'
  ),
);

/* istanbul ignore next */
const CarouselIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-carousel" */
    '../../../carousel/carousel-intervention'
  ),
);

/* istanbul ignore next */
const BillboardIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-billboard" */
    '../../../billboard/billboard-intervention.mobile'
  ),
);

/* istanbul ignore next */
const CompatsIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-compats" */
    '../../../compats/compats-intervention'
  ),
);

/* istanbul ignore next */
const BrandAdsMobile = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-brand-ads" */
    '../../../../../components/brand-ads/brand-ads.mobile'
  ),
);

/* istanbul ignore next */
const ShoppingCartIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-shopping-cart" */
    '../../../../../components/intervention/shopping-cart'
  ),
);

/* istanbul ignore next */
const TrendIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-trend" */
    '../../../trend-intervention/trend-intervention.mobile'
  ),
);

/* istanbul ignore next */
const BrandDiscoIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-brand-disco" */
    '../../../brand-disco/brand-disco.mobile'
  ),
);

/* istanbul ignore next */
const MeliPlayIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-meliplay" */
    '../../../meliplay'
  ),
);

/* istanbul ignore next */
const ZrpDisclaimerIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-zrp-disclaimer" */
    '../../../zrp-disclaimer/zrp-disclaimer'
  ),
);

/* istanbul ignore next */
const AndesMessageIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-andes-messsage" */
    '../../../../../components/intervention/andes-message-intervention/andes-message-intervention'
  ),
);

/* istanbul ignore next */
const MoreInfoIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-more-info" */
    '../../../../../components/intervention/more-info-intervention/more-info-intervention.mobile'
  ),
);

/* istanbul ignore next */
const QuickAccessIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-quick-access-intervention" */
    '../../../../../components/intervention/quick-access-intervention/mobile'
  ),
);

// Reminder: When creating a new intervention component, the top hierarchical node must be a `div` so as not to generate a mismatch error.

export const renderInterventionsMobile = {
  FILTER_INTERVENTION: FilterIntervention,
  CONTENT_INTERVENTION: ContentIntervention,
  CAROUSEL_INTERVENTION: CarouselIntervention,
  BILLBOARD_INTERVENTION: BillboardIntervention,
  COMPATS_INTERVENTION: CompatsIntervention,
  BRAND_ADS_INTERVENTION: BrandAdsMobile,
  CART_INTERVENTION: ShoppingCartIntervention,
  TREND_INTERVENTION: TrendIntervention,
  BRAND_DISCO_INTERVENTION: BrandDiscoIntervention,
  MELIPLAY_INTERVENTION: MeliPlayIntervention,
  SEO_TOP_KEYWORDS_INTERVENTION: SeoTopKeywordsIntervention,
  ZRP_DISCLAIMER_COMPONENT: ZrpDisclaimerIntervention,
  ANDES_MESSAGE_INTERVENTION: AndesMessageIntervention,
  PHARMA_INTERVENTION: MoreInfoIntervention,
  QUICK_ACCESS_INTERVENTION: QuickAccessIntervention,
  SEO_KEYWORD_SUMMARY_INTERVENTION: SeoKeywordSummaryIntervention,
};
