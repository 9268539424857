import { DEVICE_TYPES } from '../../../../constants';
import { renderInterventionsDesktop } from './render-interventions/interventions.desktop';
import { renderInterventionsMobile } from './render-interventions/interventions.mobile';

/**
 * Selects the appropriate intervention component based on the device type and item id.
 *
 * @param {Object} item - The intervention item.
 * @param {string} deviceType - The type of device (mobile or desktop).
 * @returns {React.Component|null} The selected intervention component or null if not found.
 */
export const selectInterventionComponent = (item, deviceType) =>
  deviceType === DEVICE_TYPES.MOBILE ? renderInterventionsMobile[item.id] : renderInterventionsDesktop[item.id] || null;
