import { number, shape, string } from 'prop-types';
import React, { useMemo } from 'react';

import { LazyHydrate } from 'nordic/lazy';

import { ResultPropTypes } from '../../layout/constants/result.prop-types';
import { selectInterventionComponent } from './utils';
import { withComponentHandler } from '../../../hocs/with-component-handler';
import { INTERVENTION_NO_LIST, LAYOUTS, TYPE_RESULTS } from '../../../constants';

/**
 * @typedef {Object} ResultPropTypes
 * @property {string} id
 * @property {Object} content
 */

/**
 * Renderiza el componente de intervención basado en el tipo de dispositivo y el índice.
 *
 * @param {Object} props
 * @param {ResultPropTypes} props.item - El elemento de intervención.
 * @param {string} props.deviceType - El tipo de dispositivo.
 * @param {string} props.layout - El tipo de layout.
 * @param {number} props.index - El índice de la intervención.
 * @returns {JSX.Element|null} El componente de intervención o null si no hay componente.
 */
export const InterventionsComponent = ({ item, deviceType, layout, index, ...rest }) => {
  const InterventionComponent = useMemo(() => selectInterventionComponent(item, deviceType), [item, deviceType]);

  if (!InterventionComponent) {
    return null;
  }

  if (
    layout === LAYOUTS.TYPE_GRID &&
    (item?.id === TYPE_RESULTS.CART_INTERVENTION || item?.id === TYPE_RESULTS.FOUR_STARS_INTERVENTION)
  ) {
    item.classname = 'grid__intervention';
  }

  return (
    <>
      {INTERVENTION_NO_LIST.includes(item.id) ? (
        <LazyHydrate whenVisible>
          <InterventionComponent
            {...rest}
            intervention={item}
            deviceType={deviceType}
            position={index}
            layout={layout}
            className="ui-search-layout__item--intervention-no-list"
          />
        </LazyHydrate>
      ) : (
        <li className="ui-search-layout__item--intervention" key={`${item.id}-${index}`}>
          <LazyHydrate whenVisible>
            <InterventionComponent
              {...rest}
              intervention={item}
              deviceType={deviceType}
              position={index}
              layout={layout}
            />
          </LazyHydrate>
        </li>
      )}
    </>
  );
};

InterventionsComponent.propTypes = {
  deviceType: string.isRequired,
  index: number.isRequired,
  item: shape(ResultPropTypes).isRequired,
  layout: string.isRequired,
};

export const Interventions = withComponentHandler(InterventionsComponent, {
  componentName: 'Interventions',
});
