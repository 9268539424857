import { loadable } from 'nordic/lazy';

/* istanbul ignore next */
const SeoKeywordSummaryIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-seo-keywords-summary-intervantion" */
    '../../../seo-keyword-summary-intervention/seo-keyword-summary-intervention.desktop'
  ),
);

/* istanbul ignore next */
const FilterIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-filter" */
    '../../../filter-intervention/desktop'
  ),
);

/* istanbul ignore next */
const CarouselIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-carousel" */
    '../../../carousel/carousel-intervention'
  ),
);

/* istanbul ignore next */
const ShoppingCartIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-shoppingcart" */
    '../../../../../components/intervention/shopping-cart'
  ),
);

/* istanbul ignore next */
const CompatsIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-compats" */
    '../../../compats/compats-intervention'
  ),
);

/* istanbul ignore next */
const BillboardIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-billboard" */
    '../../../billboard/billboard-intervention.desktop'
  ),
);

/* istanbul ignore next */
const FacetedSearchIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-faceted-search" */
    '../../../../../components/intervention/faceted-search/faceted-search-intervention.desktop'
  ),
);

/* istanbul ignore next */
const MeliPlayIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-meliplay" */
    '../../../meliplay'
  ),
);

/* istanbul ignore next */
const VehicleBodySelectorIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-vehicle-body-selector" */
    '../../../vehicle-body-selector'
  ),
);

/* istanbul ignore next */
const ZrpDisclaimerIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-zrp-disclaimer" */
    '../../../zrp-disclaimer/zrp-disclaimer'
  ),
);

/* istanbul ignore next */
const AndesMessageIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-andes-messsage" */
    '../../../../../components/intervention/andes-message-intervention/andes-message-intervention'
  ),
);

/* istanbul ignore next */
const MoreInfoIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-more-info" */
    '../../../../../components/intervention/more-info-intervention/more-info-intervention.desktop'
  ),
);

/* istanbul ignore next */
const QuickAccessIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-quick-access-intervention" */
    '../../../../../components/intervention/quick-access-intervention/desktop'
  ),
);

const VisPadsLiteIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-vis-pads-lite-intervention" */
    '../../../vis-pads-lite'
  ),
);

// Reminder: When creating a new intervention component, the top hierarchical node must be a `div` so as not to generate a mismatch error.

export const renderInterventionsDesktop = {
  FILTER_INTERVENTION: FilterIntervention,
  CAROUSEL_INTERVENTION: CarouselIntervention,
  CART_INTERVENTION: ShoppingCartIntervention,
  COMPATS_INTERVENTION: CompatsIntervention,
  BILLBOARD_INTERVENTION: BillboardIntervention,
  FACETED_SEARCH_INTERVENTION: FacetedSearchIntervention,
  MELIPLAY_INTERVENTION: MeliPlayIntervention,
  VEHICLE_BODY_SELECTOR_INTERVENTION: VehicleBodySelectorIntervention,
  ZRP_DISCLAIMER_COMPONENT: ZrpDisclaimerIntervention,
  ANDES_MESSAGE_INTERVENTION: AndesMessageIntervention,
  PHARMA_INTERVENTION: MoreInfoIntervention,
  QUICK_ACCESS_INTERVENTION: QuickAccessIntervention,
  SEO_KEYWORD_SUMMARY_INTERVENTION: SeoKeywordSummaryIntervention,
  VIS_PADS_LITE_INTERVENTION: VisPadsLiteIntervention,
};
